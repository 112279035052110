import React from "react";
import styled from "@emotion/styled";

import PageHeader from "../components/pageLayout/PageHeader";
import Tabs from "../components/Tabs";
import { SERVICES } from "../utils";
import { DESKTOP_WIDTH, TABLET_WIDTH } from "../defaultStyle";

const TAB_NAMES = [
  "Haircut",
  "Perm",
  "Colour",
  "Bleach",
  "Add-ons & Treatments",
];

const TabContentContainer = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 90%;

  @media screen and (min-width: ${TABLET_WIDTH}px) {
    width: 80%;
    gap: 3rem;
  }
  @media screen and (min-width: ${DESKTOP_WIDTH}px) {
    width: 60%;
    gap: 4rem;
  }
`;
const TabContentSectionWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  gap: 1rem;

  @media screen and (min-width: ${TABLET_WIDTH}px) {
    gap: 1rem;
  }
  @media screen and (min-width: ${DESKTOP_WIDTH}px) {
    gap: 2rem;
  }
`;
const SectionTitle = styled.div`
  flex: 1;
  font-size: 1rem;
  letter-spacing: 4px;
  padding: 1rem 1rem;

  @media screen and (min-width: ${TABLET_WIDTH}px) {
    padding: 1rem 2rem;
  }
`;
const SectionContent = styled.div`
  flex: 2;
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  border-left: solid 1px ${(props) => props.borderColor || "black"};
`;
const SectionContentRow = styled.div`
  display: flex;
  flex-direction: row nowrap;
  justify-content: space-between;
  font-size: 1rem;
  letter-spacing: 2px;
  gap: 2rem;
`;
const ContentNameWrapper = styled.div`
  line-height: 1.5rem;
`;

const ContentPriceWrapper = styled.div`
  text-align: right;
`;

const Service = (props) => {
  return (
    <>
      <PageHeader
        alt="Service header"
        src="/images/banner.png"
        title="Services"
      />
      <Tabs names={TAB_NAMES}>
        {SERVICES.map((service, index) => (
          <TabContentContainer key={`service-${index}`}>
            {service.sections.map((section) => (
              <TabContentSectionWrapper>
                <SectionTitle>{section.name}</SectionTitle>
                <SectionContent>
                  {section.contents.map((content, id) => (
                    <SectionContentRow key={`content-${id}`}>
                      <ContentNameWrapper>{content.name}</ContentNameWrapper>
                      <ContentPriceWrapper>
                        {content.price ? content.price : "by consulation"}
                      </ContentPriceWrapper>
                    </SectionContentRow>
                  ))}
                </SectionContent>
              </TabContentSectionWrapper>
            ))}
          </TabContentContainer>
        ))}
      </Tabs>
    </>
  );
};

Service.propTypes = {};

export default Service;
