import React from "react";
import styled from "@emotion/styled";

import Section, { SectionTitle, SectionContent } from "../components/Section";
import Link from "../components/text/Link";
import Gallery from "../components/Gallery";
import ImageFrame from "../components/ImageFrame";

import PlaceIcon from "@mui/icons-material/Place";
import InstagramIcon from "@mui/icons-material/Instagram";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import PersonIcon from "@mui/icons-material/Person";
import { TABLET_WIDTH } from "../defaultStyle";

const WorkSectionWrapper = styled.div`
  width: 100%;
  padding-left: 5%;

  @media screen and (min-width: ${TABLET_WIDTH}px) {
    padding: 0;
  }
`;

const ContentLinkWrapper = styled.div`
  display: flex;
  justify-content: ${(props) => (props.end ? "end" : "start")};
`;

const ServiceContentWrapper = styled.div`
  padding: 3% 20%;
  display: row;
  flex-flow: column nowrap;

  @media screen and (max-width: ${TABLET_WIDTH}px) {
    padding: 3% 0%;
  }
`;
const ServiceRowWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  font-size: 16px;
  height: 50px;
`;

const ContactInfoContentWrapper = styled.div`
  display: row;
  flex-flow: column nowrap;
  width: 300px;
  margin: 0 auto;
`;
const ContactInfoRowWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 16px;
  font-size: 16px;
  height: 50px;
`;
const ContactIconWrapper = styled.div`
  width: 24px;
  height: 24px;
`;
const NameWrapper = styled.div`
  width: 60%;
  @media screen and (min-width: ${TABLET_WIDTH}px) {
    width; 80%;
  }
`;
const PriceWrapper = styled.div`
  width: 40%;

  @media screen and (min-width: ${TABLET_WIDTH}px) {
    width: 20%;
  }
`;

const renderServiceRows = () => (
  <>
    <ServiceRowWrapper>
      <NameWrapper>Haircut</NameWrapper>
      <PriceWrapper>From $90</PriceWrapper>
    </ServiceRowWrapper>
    <ServiceRowWrapper>
      <NameWrapper>Perm</NameWrapper>
      <PriceWrapper>From $188</PriceWrapper>
    </ServiceRowWrapper>
    <ServiceRowWrapper>
      <NameWrapper>Colour</NameWrapper>
      <PriceWrapper>From $186</PriceWrapper>
    </ServiceRowWrapper>
    <ServiceRowWrapper>
      <NameWrapper>Bleach</NameWrapper>
      <PriceWrapper>From $128</PriceWrapper>
    </ServiceRowWrapper>
    <ServiceRowWrapper>
      <NameWrapper>Add-ons & Treatments</NameWrapper>
      {/* TODO: jump to service add-ons page */}
      <PriceWrapper>
        <Link to="/services?service=add-ons-and-treatments">View more</Link>
      </PriceWrapper>
    </ServiceRowWrapper>
  </>
);

const renderContactInfoRows = () => (
  <>
    <ContactInfoRowWrapper>
      <ContactIconWrapper>
        <PersonIcon />
      </ContactIconWrapper>
      <div>Dennis Lee</div>
    </ContactInfoRowWrapper>
    <ContactInfoRowWrapper>
      <ContactIconWrapper>
        <EmailIcon />
      </ContactIconWrapper>
      <div>rbt424@gmail.com</div>
    </ContactInfoRowWrapper>
    <ContactInfoRowWrapper>
      <ContactIconWrapper>
        <LocalPhoneIcon />
      </ContactIconWrapper>
      <div>+61(0)468927452</div>
    </ContactInfoRowWrapper>
    <ContactInfoRowWrapper>
      <ContactIconWrapper>
        <PlaceIcon />
      </ContactIconWrapper>
      <div>51 Bridge Rd, Richmond VIC 3121 (XVI Collective)</div>
    </ContactInfoRowWrapper>
    <ContactInfoRowWrapper>
      <ContactIconWrapper>
        <InstagramIcon />
      </ContactIconWrapper>
      <div>Instagram: hair_dennis</div>
    </ContactInfoRowWrapper>
    <ContactInfoRowWrapper>
      <ContactIconWrapper>
        <svg
          width="24"
          height="24"
          xmlns="http://www.w3.org/2000/svg"
          fillRule="evenodd"
          fill="#343a40"
          clipRule="evenodd"
        >
          <path d="M19 24h-14c-2.761 0-5-2.239-5-5v-14c0-2.761 2.239-5 5-5h14c2.762 0 5 2.239 5 5v14c0 2.761-2.238 5-5 5zm-.665-6.984c1.016-.736 1.665-1.825 1.665-3.035 0-2.218-2.158-4.016-4.819-4.016-2.662 0-4.819 1.798-4.819 4.016s2.157 4.016 4.819 4.016c.55 0 1.081-.079 1.573-.22l.141-.021c.093 0 .177.028.256.074l1.055.609.093.03c.089 0 .161-.072.161-.161l-.026-.117-.217-.811-.017-.102c0-.108.053-.203.135-.262zm-8.552-11.485c-3.194 0-5.783 2.158-5.783 4.82 0 1.452.779 2.759 1.998 3.642.098.07.162.185.162.314l-.02.123-.261.972-.031.141c0 .107.086.193.193.193l.111-.036 1.266-.731c.096-.055.196-.089.307-.089l.17.025c.591.17 1.228.265 1.888.265l.318-.008c-.126-.376-.194-.772-.194-1.181 0-2.427 2.361-4.395 5.274-4.395l.314.008c-.436-2.302-2.827-4.063-5.712-4.063zm3.791 7.807c-.355 0-.642-.287-.642-.642 0-.355.287-.643.642-.643.355 0 .643.288.643.643 0 .355-.288.642-.643.642zm3.213 0c-.355 0-.642-.287-.642-.642 0-.355.287-.643.642-.643.355 0 .643.288.643.643 0 .355-.288.642-.643.642zm-8.932-3.759c-.425 0-.771-.345-.771-.771 0-.426.346-.771.771-.771.426 0 .772.345.772.771 0 .426-.346.771-.772.771zm3.856 0c-.426 0-.771-.345-.771-.771 0-.426.345-.771.771-.771.426 0 .771.345.771.771 0 .426-.345.771-.771.771z" />
        </svg>
      </ContactIconWrapper>
      <div>Wechat: LCF1914</div>
    </ContactInfoRowWrapper>
  </>
);

const Home = () => {
  return (
    <>
      <Section backgroundColor={"#F4F4F0"}>
        <WorkSectionWrapper>
          <SectionContent>
            <Gallery>
              <Link to="/salon">
                <ImageFrame
                  title="Salon"
                  alt="salon"
                  src="/images/salon/7.jpg"
                />
              </Link>
              <Link to="/projects">
                <ImageFrame
                  title="Projects"
                  alt="projects"
                  src="/images/projects/project_a/1.jpg"
                />
              </Link>
              <Link to="/trainings">
                <ImageFrame
                  title="Training"
                  alt="work3"
                  src="/images/training.jpg"
                />
              </Link>
            </Gallery>
          </SectionContent>
        </WorkSectionWrapper>

        <SectionTitle to="/work" end>
          Work
        </SectionTitle>
      </Section>
      <Section backgroundColor={"#ECECEC"}>
        <SectionTitle to="/services">Services</SectionTitle>
        <SectionContent>
          <ContentLinkWrapper></ContentLinkWrapper>
          <ServiceContentWrapper>{renderServiceRows()}</ServiceContentWrapper>
        </SectionContent>
      </Section>
      <Section backgroundColor={"#F9F9F9"}>
        <SectionContent>
          <ContentLinkWrapper end></ContentLinkWrapper>
          <ContactInfoContentWrapper>
            {renderContactInfoRows()}
          </ContactInfoContentWrapper>
        </SectionContent>
        <SectionTitle to="/contact" end>
          Contact
        </SectionTitle>
      </Section>
    </>
  );
};

Home.propTypes = {};

export default Home;
