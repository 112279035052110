import React from "react";
import { useParams } from "react-router-dom";

import PageHeader from "../components/pageLayout/PageHeader";
import PageDescription from "../components/pageLayout/PageDescription";
// import ImageGallery, {
//   ImageGalleryItem,
//   ImageGalleryImage,
// } from "../components/ImageGallery";
import { PROJECTS } from "../utils";
import styled from "@emotion/styled";
import { DESKTOP_WIDTH, TABLET_WIDTH } from "../defaultStyle";

const ImageGallery = styled.div`
  width: 90%;
  margin: auto;
  -webkit-column-count: 1;
  -moz-column-count: 1;
  column-count: 1;

  -webkit-column-gap: 20px;
  -moz-column-gap: 20px;
  column-gap: 20px;

  @media screen and (min-width: ${TABLET_WIDTH}px) {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
  }

  @media screen and (min-width: ${DESKTOP_WIDTH}px) {
    -webkit-column-count: 4;
    -moz-column-count: 4;
    column-count: 4;
  }
`;

const ImageContainer = styled.img`
  width: 100%;
  height: auto;
  margin: 4% auto;
  box-shadow: -3px 5px 15px #000;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
`;

const Project = (props) => {
  const { projectId } = useParams();
  console.log("project id:", projectId);
  const projectFullName = PROJECTS[projectId].name;
  const numOfPhotos = PROJECTS[projectId].numOfPhotos;
  const description = PROJECTS[projectId].description;
  return (
    <>
      <PageHeader
        alt={`${projectFullName} Header`}
        src="/images/banner.png"
        title={projectFullName}
      />
      <PageDescription backgroundColor="#8d99ae">{description}</PageDescription>
      <ImageGallery>
        {Array.from({ length: numOfPhotos }, (_, i) => i + 1).map(
          (ele, idx) => (
            <div key={idx}>
              <ImageContainer
                alt="0"
                src={`/images/projects/${projectId}/${ele}.jpg`}
              />
            </div>
          )
        )}
      </ImageGallery>
    </>
  );
};

Project.propTypes = {};

export default Project;
