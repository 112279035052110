import React from "react";
import styled from "@emotion/styled";

import PageHeader from "../components/pageLayout/PageHeader";
import PageDescription from "../components/pageLayout/PageDescription";
import Link from "../components/text/Link";

import ProjectSection, {
  ProjectImage,
  ProjectDescription,
} from "../components/ProjectSection";

const ProjectSectionWrapper = styled(ProjectSection)`
  width: 100%;
  padding: 0 3rem;
  gap: 6rem;
  ${(props) =>
    props.backgroundColor && `background-color: ${props.backgroundColor}`}
`;
const ProjectImageWrapper = styled(ProjectImage)`
  padding: 2rem 0;
`;
const ProjectDescriptionWrapper = styled(ProjectDescription)`
  padding: 4rem 0;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
`;

const Trainings = ({ props }) => {
  return (
    <>
      <PageHeader
        alt="trainings header"
        src="/images/banner.png"
        title="Training"
      />
      <PageDescription backgroundColor="#8d99ae">
        What an incredible journey! It's wonderful that I've had the opportunity
        to participate in various training courses, including prestigious ones
        like Vidal Sassoon in London and attending backstage at New York Fashion
        Week. Collaborating with renowned hair artist Laurent Phillipon from
        BRYANT ARTISTS in PARIS. and I have been an amazing experience working
        and learning in Paris & Milan. It's great to see how these opportunities
        have deepened my love for my job and career. It's always important to
        express gratitude to those who have supported and encouraged me along
        the way. Keep up the fantastic work!
      </PageDescription>
      <ProjectSectionWrapper key="trainings1" backgroundColor="#F4F4F0">
        <ProjectImageWrapper
          src="/images/trainings/training_a/milan/1.jpg"
          alt="training a"
        />
        <ProjectDescriptionWrapper>
          <div>
            Absolutely! It's an amazing journey of being able to work with
            talented individuals like hair artist named 'Laurent Phillipon' from
            Bryant Artist and being involved in prestigious events like Milan
            Fashion Week, photo shoots for Buffalozine in Paris, and a Pepsi Co.
            commercial shoot in Milan must have been a dream come true. From the
            projects provides a wonderful opportunity for me to growth and
            learning. Cherish the experience and the knowledge you gained from
            working with those teams. It's always valuable to learn from
            industry experts and collaborate with talented individuals.
          </div>
          <Link
            to="/trainings/training_a"
            style={{
              marginRight: 0,
              marginLeft: "auto",
            }}
          >
            With Laurent Phillipon
          </Link>
        </ProjectDescriptionWrapper>
      </ProjectSectionWrapper>
      <ProjectSectionWrapper key="trainings2" backgroundColor="#F1F3F8">
        <ProjectDescriptionWrapper>
          <div>
            The NEW YORK Backstage Fashion Week course offers a comprehensive
            2-day experience where participants gain valuable insights and
            skills related to fashion shows and events. Throughout the seminar,
            attendees will learn a variety of classic and contemporary
            hairstyling techniques, enabling them to create diverse looks
            suitable for the runway. Collaboration with designers is emphasized,
            teaching participants the importance of effective communication and
            teamwork to bring creative visions to life. The course also provides
            a unique opportunity to meet and greet a guest designer and models
            then receiving industry exposure and valuable insights into their
            creative process. Participants will gain hands-on experience by
            working on live models, allowing them to practice and refine their
            skills in real-time scenarios. Finally, the course culminates with a
            mini runway or model installation show, allowing participants to
            showcase their newly acquired skills and creativity to an audience.
          </div>
          <Link to="/trainings/training_b">
            New York Backstage Fashion Week
          </Link>
        </ProjectDescriptionWrapper>
        <ProjectImageWrapper
          src="/images/trainings/training_b/1.jpg"
          alt="training b"
        />
      </ProjectSectionWrapper>
      <ProjectSectionWrapper key="trainings3" backgroundColor="#FCF5EE">
        <ProjectImageWrapper
          src="/images/trainings/training_c/1.jpg"
          alt="training c"
        />
        <ProjectDescriptionWrapper>
          <div>
            ABC colour course offers an ideal opportunity to learn the
            techniques employed by Sassoon trained colourists in this exciting
            field. By mastering the skill of selecting and applying colour, I
            can significantly enhance my earning potential. Throughout the
            course,I can delve into colour chart theory and explore various
            techniques such as total, partial, and combination colour through
            interactive demonstrations and hands-on sessions. As a result, I
            will gain confidence in choosing colours that beautifully complement
            my clients' skin tone and applying techniques that bring a fresh
            dimension to their haircut. I firmly believe that acquiring these
            essential skills before advancing to more complex work will greatly
            benefit your professional growth.
          </div>
          <Link
            to="/trainings/training_c"
            style={{
              marginRight: 0,
              marginLeft: "auto",
            }}
          >
            London Vidal Sassoon (ABC Color)
          </Link>
        </ProjectDescriptionWrapper>
      </ProjectSectionWrapper>
    </>
  );
};

Trainings.propTypes = {};

export default Trainings;
