import React from "react";
import styled from "@emotion/styled";
import { useParams } from "react-router-dom";

import PageHeader from "../components/pageLayout/PageHeader";
import PageDescription from "../components/pageLayout/PageDescription";
// import ImageGallery, {
//   ImageGalleryItem,
//   ImageGalleryImage,
// } from "../components/ImageGallery";
import { TRAININGS } from "../utils";
import { DESKTOP_WIDTH, TABLET_WIDTH } from "../defaultStyle";

const ImageGallery = styled.div`
  width: 90%;
  margin: auto;
  -webkit-column-count: 1;
  -moz-column-count: 1;
  column-count: 1;

  -webkit-column-gap: 20px;
  -moz-column-gap: 20px;
  column-gap: 20px;

  @media screen and (min-width: ${TABLET_WIDTH}px) {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
  }

  @media screen and (min-width: ${DESKTOP_WIDTH}px) {
    -webkit-column-count: 4;
    -moz-column-count: 4;
    column-count: 4;
  }
`;

const ImageContainer = styled.img`
  width: 100%;
  height: auto;
  margin: 4% auto;
  box-shadow: -3px 5px 15px #000;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
`;

const SectionTitleWrapper = styled.div`
  padding: 2rem 4rem;
  font-size: 2rem;
  text-decoration: underline;
`;

const Project = (props) => {
  const { trainingId } = useParams();
  console.log("training id:", trainingId);
  const trainingFullName = TRAININGS[trainingId].name;
  const numOfPhotos = TRAININGS[trainingId].numOfPhotos;
  const description = TRAININGS[trainingId].description;

  return (
    <>
      <PageHeader
        alt={`${trainingFullName} Header`}
        src="/images/banner.png"
        title={trainingFullName}
      />
      <PageDescription backgroundColor="#8d99ae">{description}</PageDescription>
      {trainingId === "training_a" ? (
        <>
          {TRAININGS[trainingId].contents.map((content) => (
            <>
              <SectionTitleWrapper>{content.name}</SectionTitleWrapper>
              <ImageGallery>
                {Array.from(
                  { length: content.numOfPhotos },
                  (_, i) => i + 1
                ).map((ele, idx) => (
                  <div key={idx}>
                    <ImageContainer
                      alt="0"
                      src={`/images/trainings/${trainingId}/${content.folderName}/${ele}.jpg`}
                    />
                  </div>
                ))}
              </ImageGallery>
            </>
          ))}
        </>
      ) : (
        <ImageGallery>
          {Array.from({ length: numOfPhotos }, (_, i) => i + 1).map(
            (ele, idx) => (
              <div key={idx}>
                <ImageContainer
                  alt="0"
                  src={`/images/trainings/${trainingId}/${ele}.jpg`}
                />
              </div>
            )
          )}
        </ImageGallery>
      )}
    </>
  );
};

Project.propTypes = {};

export default Project;
