import React, { Children } from "react";
import styled from "@emotion/styled";
import { TABLET_WIDTH } from "../defaultStyle";

const GalleryContainer = styled.div`
  display: flex;
  gap: 25px;
  flex-direction: column;

  @media screen and (min-width: ${TABLET_WIDTH}px) {
    flex-flow: row nowrap;
  }
`;
const GalleryItemWrapper = styled.div`
  flex: 1;
`;

const Gallery = ({ children, ...restProps }) => {
  return (
    <GalleryContainer {...restProps}>
      {Children.map(children, (child) => (
        <GalleryItemWrapper>{child}</GalleryItemWrapper>
      ))}
    </GalleryContainer>
  );
};

Gallery.propTypes = {};
Gallery.defaultProps = {
  itemsPerRow: 4,
};

export default Gallery;
