import React from "react";
import styled from "@emotion/styled";

import PageHeader from "../components/pageLayout/PageHeader";
import PageDescription from "../components/pageLayout/PageDescription";
import Link from "../components/text/Link";

import ProjectSection, {
  ProjectImage,
  ProjectDescription,
} from "../components/ProjectSection";
import { DESKTOP_WIDTH, TABLET_WIDTH } from "../defaultStyle";

const ProjectSectionWrapper = styled(ProjectSection)`
  width: 100%;
  ${(props) =>
    props.backgroundColor && `background-color: ${props.backgroundColor}`};

  padding: 0 1rem;
  gap: 1rem;

  @media screen and (min-width: ${TABLET_WIDTH}px) {
    padding: 0 2rem;
    gap: 2rem;
  }
  @media screen and (min-width: ${DESKTOP_WIDTH}px) {
    padding: 0 4rem;
    gap: 6rem;
  }
`;
const ProjectImageWrapper = styled(ProjectImage)`
  padding: 1rem 0;

  @media screen and (min-width: ${TABLET_WIDTH}px) {
    padding: 2rem 0;
  }
`;
const ProjectDescriptionWrapper = styled(ProjectDescription)`
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  padding: 1rem 0;

  @media screen and (min-width: ${TABLET_WIDTH}px) {
    padding: 2rem 0;
  }
  @media screen and (min-width: ${DESKTOP_WIDTH}px) {
    padding: 4rem 0;
  }
`;
const LinkWrapper = styled(Link)`
  letter-spacing: 2px;
  margin-left: ${(props) => (props.right ? "auto" : "0")};
  margin-right: ${(props) => (props.right ? "0" : "auto")};
`;

const Projects = ({ props }) => {
  return (
    <>
      <PageHeader
        alt="Project header"
        src="/images/banner.png"
        title="Projects"
      />
      <PageDescription backgroundColor="#8d99ae">
        Over the years, I have dedicated myself to studying and learning, and
        now I am ready to utilize the skills and knowledge I have acquired. My
        aim is to effectively communicate with professionals from diverse
        fields, fostering mutual growth and learning. Additionally, I have
        attended some personal projects which serves as a platform for
        exchanging knowledge and experiences. Furthermore, I actively engage in
        collaborative efforts with fellow students, as well as participate in
        workshops and other collaborative events. Through these endeavors, I am
        confident that I will continue to develop and expand my expertise.
      </PageDescription>
      <ProjectSectionWrapper key="Project1" backgroundColor="#F9F3F3">
        <ProjectImageWrapper
          src="/images/projects/project_a/2.jpg"
          alt="Project 1"
        />
        <ProjectDescriptionWrapper>
          <div>
            Preparing to attend the world of high fashion photography at Sony's
            Workshop in Melbourne. This comprehensive workshop shows every step
            of the process, from the makeup stage to hair, styling, lighting,
            creative direction, and posing direction. With expert guidance and
            hands-on experience, i have the opportunity to refine my skills of
            taking photo and know how to capture stunning fashion images. It's
            an exciting and educational journey into the fashion photography.
          </div>

          <LinkWrapper to="/projects/project_a" right>
            Sony Workshop
          </LinkWrapper>
        </ProjectDescriptionWrapper>
      </ProjectSectionWrapper>
      <ProjectSectionWrapper key="Project2" backgroundColor="#F7F3E9">
        <ProjectDescriptionWrapper>
          <div>
            Purple May's high fashion jewelry collection is a stunning display
            of exquisite craftsmanship and elegant design also embodies timeless
            beauty and refinement. Commercial fashion photography is utilized to
            highlight the allure and sophistication of the jewelry,
            complementing the overall aesthetic. To ensure a flawless
            presentation, every aspect, from makeup and hair to the models'
            posture, must exude elegance and perfection. By collaborating with a
            talented team of professionals, I have the opportunity to learn from
            one another and create exceptional work. A professional and
            collaborative working attitude is essential to executing this
            project successfully.
          </div>
          <LinkWrapper to="/projects/project_b">
            Purplemay Jewelry
          </LinkWrapper>
        </ProjectDescriptionWrapper>
        <ProjectImageWrapper
          src="/images/projects/project_b/9.jpg"
          alt="Project 2"
        />
      </ProjectSectionWrapper>
      <ProjectSectionWrapper key="Project3" backgroundColor="#CCCCCC">
        <ProjectImageWrapper
          src="/images/projects/project_c/5.jpg"
          alt="Project 3"
        />
        <ProjectDescriptionWrapper>
          <div>
            What an honor collaborating with photographer Lucius and an elegant
            female model. In this artistic endeavor, Lucius skillfully captures
            an intellectual beauty that transcends age, showcasing a vision that
            embraces feminism and celebrates diverse notions of beauty. Through
            the meticulous combination of various outfits, hairstyles, and
            makeup, the images exude a sense of nobility and generosity,
            enveloping in a charming atmosphere . With this mesmerizing journey
            of visual storytelling and explore the profound depths of aesthetic
            expression.
          </div>
          <LinkWrapper to="/projects/project_c" right>
            Photographer Lucius
          </LinkWrapper>
        </ProjectDescriptionWrapper>
      </ProjectSectionWrapper>
      <ProjectSectionWrapper key="Project4" backgroundColor="#E8EAE6">
        <ProjectDescriptionWrapper>
          <div>
            Welcome to the world of photography! Working with photography
            student, I have the incredible opportunity to capture captivating
            images that tell stories and evoke emotions. To encourage young
            talents , one effective approach is to assist them by providing my
            skills and professional techniques. By coordinating your outfit,
            makeup, and hair, you can create a cohesive and visually appealing
            composition. Additionally, exploring different styles, such as the
            timeless elegance of black and white or the bold collision of
            vibrant colors, allows you to infuse your work with creativity and
            expressiveness. Embracing enthusiasm and passion for photography
            also on this exciting journey of capturing moments and creating art.
          </div>

          <LinkWrapper to="/projects/project_d">Photography student</LinkWrapper>
        </ProjectDescriptionWrapper>
        <ProjectImageWrapper
          src="/images/projects/project_d/4.jpg"
          alt="Project 4"
        />
      </ProjectSectionWrapper>
    </>
  );
};

Projects.propTypes = {};

export default Projects;
