import React from "react";
import styled from "@emotion/styled";

import { DESKTOP_WIDTH } from "../../defaultStyle";

const PageDescriptionContainer = styled.div`
  width: 100%;
  padding: 5%;

  @media screen and (min-width: ${DESKTOP_WIDTH}px) {
    padding: 5% 5% 5% 40%;
  }
`;
const DescriptionWrapper = styled.div`
  font-family: "Prata", serif;
  text-align: justify;
  line-height: 1.5rem;
  color: #f8f9fa;

  @media screen and (min-width: ${DESKTOP_WIDTH}px) {
    letter-spacing: px;
    line-height: 2rem;
  }
`;

const PageDescription = ({ backgroundColor, children }) => {
  return (
    <PageDescriptionContainer
      style={{
        backgroundColor,
      }}
    >
      <DescriptionWrapper>{children}</DescriptionWrapper>
    </PageDescriptionContainer>
  );
};

PageDescription.propTypes = {};

export default PageDescription;
