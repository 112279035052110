import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Global, css } from "@emotion/react";

import Layout from "./components/layouts/Layout";
import Home from "./pages/Home";
import Service from "./pages/Service";
import Work from "./pages/Work";
import Salon from "./pages/Salon";
import Projects from "./pages/Projects";
import Project from "./pages/Project";
import Trainings from "./pages/Trainings";
import Training from "./pages/Training";
import Bio from "./pages/Bio";
import Contact from "./pages/Contact";
import Error from "./pages/Error";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    errorElement: <Error />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: "home",
        element: <Home />,
      },
      {
        path: "work",
        element: <Work />,
      },
      {
        path: "salon",
        element: <Salon />,
      },
      {
        path: "projects",
        element: <Projects />,
      },
      {
        path: "projects/:projectId",
        element: <Project />,
      },
      {
        path: "trainings",
        element: <Trainings />,
      },
      {
        path: "trainings/:trainingId",
        element: <Training />,
      },
      {
        path: "services",
        element: <Service />,
      },
      {
        path: "bio",
        element: <Bio />,
      },
      {
        path: "contact",
        element: <Contact />,
      },
    ],
  },
]);

function App() {
  return (
    <>
      <Global
        styles={css`
          *,
          *::before,
          *::after,
          html {
            box-sizing: border-box;
            padding: 0;
            margin: 0;
            font-family: "Mukta", sans-serif;
            font-size: 16px;
            color: #6c757d;
          }
          body {
            color: #343a40;
            text-rendering: geometricPrecision;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            background-color: #ced4da;
          }
          svg {
            height: 100%;
          }
          // html,
          // body {
          //   width: 100vw;
          //   min-height: 100vh;
          // }
        `}
      />
      <RouterProvider router={router} />
    </>
  );
}

export default App;
