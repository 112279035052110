import React, { useState, Children } from "react";
import styled from "@emotion/styled";
import { DESKTOP_WIDTH, TABLET_WIDTH } from "../defaultStyle";
import { useSearchParams } from "react-router-dom";

const TabsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
const TabBar = styled.div`
  display: flex;
  overflow-x: scroll;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 2rem;
`;
const TabButton = styled.div`
  font-size: 1rem;
  letter-spacing: 2px;
  padding: 0;
  ${(props) => (props.active ? "text-decoration: underline;" : "")}

  &:hover {
    cursor: pointer;
  }

  @media screen and (min-width: ${TABLET_WIDTH}px) {
    padding: 0 1rem;
  }
`;
const TabContentWrapper = styled.div`
  display: ${(props) => (props.active ? "block" : "none")};
  width: 100%;
  padding: 1rem 0;

  @media screen and (min-width: ${TABLET_WIDTH}px) {
    padding: 2rem 0 4rem;
  }
  @media screen and (min-width: ${DESKTOP_WIDTH}px) {
    padding: 2rem 0 6rem;
  }
`;

const Tabs = ({ names, children, ...restProps }) => {
  const [searchParams] = useSearchParams();
  const defaultIndex = searchParams.get("service");

  const [tabNum, setTabNum] = useState(
    defaultIndex === "add-ons-and-treatments" ? 4 : 0
  );

  return (
    <TabsContainer {...restProps}>
      <TabBar>
        {names.map((name, idx) => (
          <TabButton
            key={name}
            active={idx === tabNum}
            onClick={() => setTabNum(idx)}
          >
            {name}
          </TabButton>
        ))}
      </TabBar>
      {children &&
        Children.map(children, (child, idx) => {
          return (
            <TabContentWrapper
              active={idx === tabNum}
              key={`tab-content-${idx}`}
            >
              {child}
            </TabContentWrapper>
          );
        })}
    </TabsContainer>
  );
};

Tabs.propTypes = {};

export default Tabs;
