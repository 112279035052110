import React from "react";
import styled from "@emotion/styled";
import Link from "../text/Link";

import InstagramIcon from "@mui/icons-material/Instagram";
import ImageFrame from "../ImageFrame";
import { TABLET_WIDTH } from "../../defaultStyle";

const NavbarWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  padding: 0;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  gap: 5px;

  @media screen and (min-width: ${TABLET_WIDTH}px) {
    padding: 0 40px;
  }
`;

const LogoContainer = styled.div`
  height: 90%;
`;

const LinkContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 10px;
  align-items: flex-end;
  line-height: 1rem;

  @media screen and (min-width: ${TABLET_WIDTH}px) {
    flex-flow: row nowrap;
  }
`;

const LinkWrapper = styled(Link)`
  flex: 1 1 0;
  padding: 5px;
  font-size: 1rem;
  letter-spacing: 3px;
  text-underline-offset: 3px;
`;

const SocialContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  gap: 5px;
`;

const Navbar = (props) => {
  return (
    <NavbarWrapper>
      <LogoContainer>
        <img
          alt="logo"
          src="/images/Logo.svg"
          style={{
            width: "80px",
            height: "80px",
          }}
          // style="background-color:#f6f6f6;"
        />
      </LogoContainer>
      <LinkContainer>
        <LinkWrapper to="/home">Home</LinkWrapper>
        <LinkWrapper to="/work">Work</LinkWrapper>
        <LinkWrapper to="/services">Services</LinkWrapper>
        <LinkWrapper to="/bio">Bio</LinkWrapper>
        <LinkWrapper to="/contact">Contact</LinkWrapper>
      </LinkContainer>
      <SocialContainer>
        <LinkWrapper to="https://instagram.com/hair_dennis?igshid=MzRlODBiNWFlZA==">
          <InstagramIcon />
        </LinkWrapper>
        {/* <LinkWrapper to="weixin://dl/chat?rbt424">
          <svg
            width="24"
            height="24"
            xmlns="http://www.w3.org/2000/svg"
            fill-rule="evenodd"
            fill="#343a40"
            clip-rule="evenodd"
          >
            <path d="M19 24h-14c-2.761 0-5-2.239-5-5v-14c0-2.761 2.239-5 5-5h14c2.762 0 5 2.239 5 5v14c0 2.761-2.238 5-5 5zm-.665-6.984c1.016-.736 1.665-1.825 1.665-3.035 0-2.218-2.158-4.016-4.819-4.016-2.662 0-4.819 1.798-4.819 4.016s2.157 4.016 4.819 4.016c.55 0 1.081-.079 1.573-.22l.141-.021c.093 0 .177.028.256.074l1.055.609.093.03c.089 0 .161-.072.161-.161l-.026-.117-.217-.811-.017-.102c0-.108.053-.203.135-.262zm-8.552-11.485c-3.194 0-5.783 2.158-5.783 4.82 0 1.452.779 2.759 1.998 3.642.098.07.162.185.162.314l-.02.123-.261.972-.031.141c0 .107.086.193.193.193l.111-.036 1.266-.731c.096-.055.196-.089.307-.089l.17.025c.591.17 1.228.265 1.888.265l.318-.008c-.126-.376-.194-.772-.194-1.181 0-2.427 2.361-4.395 5.274-4.395l.314.008c-.436-2.302-2.827-4.063-5.712-4.063zm3.791 7.807c-.355 0-.642-.287-.642-.642 0-.355.287-.643.642-.643.355 0 .643.288.643.643 0 .355-.288.642-.643.642zm3.213 0c-.355 0-.642-.287-.642-.642 0-.355.287-.643.642-.643.355 0 .643.288.643.643 0 .355-.288.642-.643.642zm-8.932-3.759c-.425 0-.771-.345-.771-.771 0-.426.346-.771.771-.771.426 0 .772.345.772.771 0 .426-.346.771-.772.771zm3.856 0c-.426 0-.771-.345-.771-.771 0-.426.345-.771.771-.771.426 0 .771.345.771.771 0 .426-.345.771-.771.771z" />
          </svg>
        </LinkWrapper> */}
      </SocialContainer>
    </NavbarWrapper>
  );
};

Navbar.propTypes = {};

export default Navbar;
