import React from "react";
import styled from "@emotion/styled";

import { TABLET_WIDTH } from "../../defaultStyle";

const PageHeaderWrapper = styled.div`
  width: 100%;
  position: relative;
`;
const HeaderImageWrapper = styled.img`
  display: block;
  width: 100%;
`;
const HeaderTitleWrapper = styled.div`
  position: absolute;
  left: 10%;
  bottom: 10%;
  color: #e9ecef;
  max-width: 40%;
  line-height: 3rem;
  font-size: 2.5rem;
  letter-spacing: 0.3rem;

  @media screen and (min-width: ${TABLET_WIDTH}px) {
    line-height: 4.5rem;
    font-size: 4rem;
  }
`;

const PageHeader = ({ src, alt, title, ...restProps }) => {
  return (
    <PageHeaderWrapper {...restProps}>
      <HeaderImageWrapper alt={alt} src={src} />
      <HeaderTitleWrapper>{title}</HeaderTitleWrapper>
    </PageHeaderWrapper>
  );
};

PageHeader.propTypes = {};

export default PageHeader;
