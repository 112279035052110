export const PROJECTS = {
  project_a: {
    name: "Sony Workshop",
    numOfPhotos: 7,
    description:
      "Preparing to attend the world of high fashion photography at Sony's Workshop in Melbourne. This comprehensive workshop shows every step of the process, from the makeup stage to hair, styling, lighting, creative direction, and posing direction. With expert guidance and hands-on experience, i have the opportunity to refine my skills of taking photo and know how to capture stunning fashion images. It's an exciting and educational journey into the fashion photography.",
  },
  project_b: {
    name: "Purplemay Jewelry",
    numOfPhotos: 8,
    description:
      "Purple May's high fashion jewelry collection is a stunning display of exquisite craftsmanship and elegant design also embodies timeless beauty and refinement.  Commercial fashion photography is utilized to highlight the allure and sophistication of the jewelry, complementing the overall aesthetic.  To ensure a flawless presentation, every aspect, from makeup and hair to the models' posture, must exude elegance and perfection.  By collaborating with a talented team of professionals, I have the opportunity to learn from one another and create exceptional work.  A professional and collaborative working attitude is essential to executing this project successfully.",
  },
  project_c: {
    name: "Photographer Lucius",
    numOfPhotos: 5,
    description:
      "What an honor collaborating with photographer Lucius and an elegant female model. In this artistic endeavor, Lucius skillfully captures an intellectual beauty that transcends age, showcasing a vision that embraces feminism and celebrates diverse notions of beauty. Through the meticulous combination of various outfits, hairstyles, and makeup, the images exude a sense of nobility and generosity, enveloping in a charming atmosphere . With this mesmerizing journey of visual storytelling and explore the profound depths of aesthetic expression.",
  },
  project_d: {
    name: "Photography student",
    numOfPhotos: 6,
    description:
      "Welcome to the world of photography! Working with photography student, I have the incredible opportunity to capture captivating images that tell stories and evoke emotions. To encourage young talents , one effective approach is to assist them by providing my skills and professional techniques. By coordinating your outfit, makeup, and hair, you can create a cohesive and visually appealing composition. Additionally, exploring different styles, such as the timeless elegance of black and white or the bold collision of vibrant colors, allows you to infuse your work with creativity and expressiveness. Embracing enthusiasm and passion for photography also on this exciting journey of capturing moments and creating art.",
  },
};

export const TRAININGS = {
  training_a: {
    name: "With Laurent Phillipon",
    numOfPhotos: 0,
    description:
      "Absolutely! It's an amazing journey of being able to work with talented individuals like hair artist named 'Laurent Phillipon' from Bryant Artist and being involved in prestigious events like Milan Fashion Week, photo shoots for Buffalozine in Paris, and a Pepsi Co. commercial shoot in Milan must have been a dream come true. From the projects provides a wonderful opportunity for me to growth and learning. Cherish the experience and the knowledge you gained from working with those teams. It's always valuable to learn from industry experts and collaborate with talented individuals.",
    contents: [
      { folderName: "milan", name: "Milan Fashion Week", numOfPhotos: 8 },
      { folderName: "buffalo", name: "Buffalo Zine in Paris", numOfPhotos: 6 },
      {
        folderName: "pesi_coke",
        name: "Milan Pepsi Co. Commercial Shoot",
        numOfPhotos: 11,
      },
    ],
  },
  training_b: {
    name: "New York Backstage Fashion Week",
    numOfPhotos: 12,
    description:
      "The NEW YORK Backstage Fashion Week course offers a comprehensive 2-day experience where participants gain valuable insights and skills related to fashion shows and events. Throughout the seminar, attendees will learn a variety of classic and contemporary hairstyling techniques, enabling them to create diverse looks suitable for the runway. Collaboration with designers is emphasized, teaching participants the importance of effective communication and teamwork to bring creative visions to life. The course also provides a unique opportunity to meet and greet a guest designer and models then receiving industry exposure and valuable insights into their creative process. Participants will gain hands-on experience by working on live models, allowing them to practice and refine their skills in real-time scenarios. Finally, the course culminates with a mini runway or model installation show, allowing participants to showcase their newly acquired skills and creativity to an audience.",
  },
  training_c: {
    name: "London Vidal Sassoon (ABC Color)",
    numOfPhotos: 10,
    description:
      "ABC colour course offers an ideal opportunity to learn the techniques employed by Sassoon trained colourists in this exciting field. By mastering the skill of selecting and applying colour, I can significantly enhance my earning potential. Throughout the course,I can  delve into colour chart theory and explore various techniques such as total, partial, and combination colour through interactive demonstrations and hands-on sessions. As a result, I will gain confidence in choosing colours that beautifully complement my clients' skin tone and applying techniques that bring a fresh dimension to their haircut. I firmly believe that acquiring these essential skills before advancing to more complex work will greatly benefit your professional growth.",
  },
};

export const SERVICES = [
  {
    name: "Haircut",
    sections: [
      {
        name: "Gents",
        contents: [
          {
            name: "Haircut",
            price: "from $90",
          },
        ],
      },
      {
        name: "Ladies",
        contents: [
          {
            name: "Fringe",
            price: "$10",
          },
          {
            name: "Haircut",
            price: "from $110",
          },
          {
            name: "Extra long haircut",
            price: "$130",
          },
        ],
      },
    ],
  },
  {
    name: "Perm",
    sections: [
      {
        name: "Gents",
        contents: [
          {
            name: "Perm excluding haircut",
            price: "from $188",
          },
          {
            name: "Side perm",
            price: "from $100",
          },
          {
            name: "Perm plus side perm",
            price: "from $258",
          },
          {
            name: "Medium-length perm",
            price: "from $228",
          },
          {
            name: "Special perm",
            price: 0,
          },
        ],
      },
      {
        name: "Ladies",
        contents: [
          {
            name: "Digital perm short hair",
            price: "from $288",
          },
          {
            name: "Digital perm medium hair",
            price: "from $318",
          },
          {
            name: "Digital perm long hair",
            price: "from $358",
          },
          {
            name: "Cold perm medium hair",
            price: "from $228",
          },
          {
            name: "Cold perm long hair",
            price: "from $288",
          },
          {
            name: "Special perm",
            price: 0,
          },
        ],
      },
    ],
  },
  {
    name: "Colour",
    sections: [
      {
        name: "Gents",
        contents: [
          {
            name: "All-hair color",
            price: "from $186",
          },
          {
            name: "Roots regrow(1 - 3cm)",
            price: "from $150",
          },
          {
            name: "Color design",
            price: 0,
          },
        ],
      },
      {
        name: "Ladies",
        contents: [
          {
            name: "All-hair color short",
            price: "from $226",
          },
          {
            name: "All-hair color medium",
            price: "from $256",
          },
          {
            name: "All-hair color long",
            price: "from $286",
          },
          {
            name: "Extra long hair plus",
            price: "from $50",
          },
          {
            name: "Roots regrow(1 - 3cm)",
            price: "from $150",
          },
          {
            name: "Balayage",
            price: 0,
          },
          {
            name: "Color design - partial",
            price: 0,
          },
          {
            name: "Color design - foils",
            price: 0,
          },
        ],
      },
    ],
  },
  {
    name: "Bleach",
    sections: [
      {
        name: "Gents",
        contents: [
          {
            name: "All-hair bleach",
            price: "from $128",
          },
          {
            name: "Roots bleach plus Olaplex",
            price: "from $258",
          },
        ],
      },
      {
        name: "Ladies",
        contents: [
          {
            name: "All-hair bleach medium",
            price: "from $158",
          },
          {
            name: "All-hair bleach long",
            price: "from $188",
          },
          {
            name: "Roots bleach plus Olaplex",
            price: "from $258",
          },
        ],
      },
    ],
  },
  {
    name: "Add-ons & Treatments",
    sections: [
      {
        name: "Add-ons",
        contents: [
          { name: "Heat styling", price: "$25" },
          { name: "Blow styling", price: "$70" },
          { name: "Hair up", price: "$100" },
          { name: "Express hair up", price: "$50" },
          { name: "Gents styling", price: "$30" },
          { name: "Shampoo - Gents", price: "$30" },
          { name: "Shampoo - Ladies", price: "$40" },
          { name: "Olaplex per once", price: "$38" },
        ],
      },
      {
        name: "Treatment",
        contents: [
          { name: "Silk protein - Gents", price: "$88" },
          { name: "Silk protein - Ladies", price: "$128" },
          { name: "Oribe", price: "$150" },
          {
            name: "Hair reconstruction with Olaplex plus moisture",
            price: "$180",
          },
        ],
      },
    ],
  },
];
