import React from "react";
import styled from "@emotion/styled";

const ProjectSectionWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
`;
const ProjectImageWrapper = styled.div`
  flex: 1;
`;
const ImageWrapper = styled.img`
  width: 100%;
`;
const ProjectDescriptionWrapper = styled.div`
  flex: 2;
`;

export const ProjectImage = ({ src, alt, ...restProps }) => (
  <ProjectImageWrapper {...restProps}>
    <ImageWrapper src={src} alt={alt} />
  </ProjectImageWrapper>
);

export const ProjectDescription = ({ children, ...restProps }) => (
  <ProjectDescriptionWrapper {...restProps}>
    {children}
  </ProjectDescriptionWrapper>
);

const ProjectSection = ({ children, ...restProps }) => {
  return (
    <ProjectSectionWrapper {...restProps}>{children}</ProjectSectionWrapper>
  );
};

ProjectSection.propTypes = {};

export default ProjectSection;
