import React from "react";
import styled from "@emotion/styled";
import { Link as RouterLink } from "react-router-dom";

const LinkWrapper = styled(RouterLink)`
  text-decoration: none;
  color: #343a40;
  text-decoration: underline;
  text-decoration-thickness: 1px;
`;

const Link = ({ children, ...restProps }) => {
  return <LinkWrapper {...restProps}>{children}</LinkWrapper>;
};

Link.propTypes = {};

export default Link;
