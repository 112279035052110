import React from "react";
import styled from "@emotion/styled";

import { DESKTOP_WIDTH, TABLET_WIDTH } from "../defaultStyle";
import { Link as RouterLink } from "react-router-dom";

const SectionWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  padding: 2rem 1rem;

  @media screen and (min-width: ${TABLET_WIDTH}px) {
    padding: 3rem;
  }
  @media screen and (min-width: ${DESKTOP_WIDTH}px) {
    padding: 5rem 9rem;
  }
`;
const SectionTitleContainer = styled(RouterLink)`
  writing-mode: vertical-rl;
  text-orientation: mixed;
  font-size: 3rem;
  letter-spacing: 0.5rem;
  text-underline-offset: 0.5rem;

  @media screen and (min-width: ${TABLET_WIDTH}px) {
    font-size: 4rem;
    letter-spacing: 0.5rem;
  }
  @media screen and (min-width: ${DESKTOP_WIDTH}px) {
    font-size: 5rem;
    letter-spacing: 0.2rem;
  }
`;
const SectionContentContainer = styled.div`
  flex: 1;
`;

export const SectionTitle = ({ children, ...restProps }) => {
  return (
    <SectionTitleContainer {...restProps}>{children}</SectionTitleContainer>
  );
};
export const SectionContent = ({ children, ...restProps }) => {
  return (
    <SectionContentContainer {...restProps}>{children}</SectionContentContainer>
  );
};

const Section = ({ children, backgroundColor }) => {
  return (
    <SectionWrapper
      style={{
        backgroundColor,
      }}
    >
      {children}
    </SectionWrapper>
  );
};

Section.propTypes = {};

export default Section;
