import React from "react";
import styled from "@emotion/styled";
import { Outlet } from "react-router-dom";

import Navbar from "./Navbar";
import { TABLET_WIDTH } from "../../defaultStyle";

const HeaderContainer = styled.div`
  position: sticky;
  top: 0;
  width: 100%;
  height: 6rem;
  // background-color: #f6f6f6;
  backdrop-filter: blur(6px);
  z-index: 1000;
`;
const FooterContainer = styled.div`
  @media screen and (min-width: ${TABLET_WIDTH}px) {
    position: fixed;
    bottom: 0;
    left: 0;
  }
`;
const BodyContainer = styled.div`
  width: 100%;
  min-height: calc(100vh-5rem);
`;

const FooterTextContainer = styled.div`
  padding: 0.5rem;

  @media screen and (min-width: ${TABLET_WIDTH}px) {
    writing-mode: vertical-rl;
    text-orientation: sideways-right;
  }
`;

const CURRENT_YEAR = new Date().getFullYear();

const Header = ({ children }) => {
  return <HeaderContainer>{children}</HeaderContainer>;
};
const Body = ({ children }) => {
  return <BodyContainer>{children}</BodyContainer>;
};
const Footer = ({ children }) => {
  return <FooterContainer>{children}</FooterContainer>;
};

const Layout = (props) => {
  return (
    <>
      <Header>
        <Navbar />
      </Header>
      <Body>
        <Outlet />
      </Body>
      <Footer>
        <FooterTextContainer>
          Copyright &copy; {CURRENT_YEAR} Dennis Lee Hairstylist.
        </FooterTextContainer>
      </Footer>
    </>
  );
};

Layout.propTypes = {};

export default Layout;
