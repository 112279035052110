import React from "react";

import PageHeader from "../components/pageLayout/PageHeader";
import PageDescription from "../components/pageLayout/PageDescription";
import styled from "@emotion/styled";
import { DESKTOP_WIDTH, TABLET_WIDTH } from "../defaultStyle";

const ImageGallery = styled.div`
  width: 90%;
  margin: auto;
  -webkit-column-count: 1;
  -moz-column-count: 1;
  column-count: 1;

  -webkit-column-gap: 20px;
  -moz-column-gap: 20px;
  column-gap: 20px;

  @media screen and (min-width: ${TABLET_WIDTH}px) {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
  }

  @media screen and (min-width: ${DESKTOP_WIDTH}px) {
    -webkit-column-count: 4;
    -moz-column-count: 4;
    column-count: 4;
  }
`;

const ImageContainer = styled.img`
  width: 100%;
  height: auto;
  margin: 4% auto;
  box-shadow: -3px 3px 10px #000;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
`;

const Salon = (props) => {
  return (
    <>
      <PageHeader alt="work header" src="/images/banner.png" title="Salon" />
      <PageDescription backgroundColor="#8d99ae">
        With over 15 years of experience as an experienced Hair Stylist, I bring
        a wealth of expertise to the salon. I am highly skilled in cutting and
        styling hair, and I pride myself on collaborating closely with clients
        to help them achieve their desired look and hair goals. My continuous
        commitment to professional development ensures that I stay updated on
        the latest trends, techniques, and tools in the industry. By actively
        listening to my clients' preferences and aspirations, I provide
        personalized advice and recommendations tailored to their individual
        needs. My ultimate aim is to deliver exceptional results that exceed
        expectations, leaving clients feeling confident and satisfied.
      </PageDescription>
      <ImageGallery>
        <ImageContainer alt="0" src="/images/salon/1.jpg" />
        <ImageContainer alt="1" src="/images/salon/2.jpg" />
        <ImageContainer alt="2" src="/images/salon/3.jpg" />
        <ImageContainer alt="3" src="/images/salon/4.jpg" />
        <ImageContainer alt="4" src="/images/salon/5.jpg" />
        <ImageContainer alt="5" src="/images/salon/6.jpg" />
        <ImageContainer alt="6" src="/images/salon/7.jpg" />
        <ImageContainer alt="7" src="/images/salon/8.jpg" />
        <ImageContainer alt="8" src="/images/salon/9.jpg" />
        <ImageContainer alt="9" src="/images/salon/10.jpg" />
        <ImageContainer alt="10" src="/images/salon/11.jpg" />
        <ImageContainer alt="11" src="/images/salon/12.jpg" />
        <ImageContainer alt="12" src="/images/salon/13.jpg" />
        <ImageContainer alt="13" src="/images/salon/14.jpg" />
        <ImageContainer alt="14" src="/images/salon/15.jpg" />
        <ImageContainer alt="15" src="/images/salon/16.jpg" />
        <ImageContainer alt="16" src="/images/salon/17.jpg" />
        <ImageContainer alt="17" src="/images/salon/18.jpg" />
      </ImageGallery>
    </>
  );
};

Salon.propTypes = {};

export default Salon;
