import React from "react";
import styled from "@emotion/styled";

import ProjectSection, {
  ProjectImage,
  ProjectDescription,
} from "../components/ProjectSection";
import { DESKTOP_WIDTH, TABLET_WIDTH } from "../defaultStyle";

const BioWrapper = styled(ProjectSection)`
  width: 100%;
  ${(props) =>
    props.backgroundColor && `background-color: ${props.backgroundColor}`};
  gap: 1rem;
  padding: 0 1rem;
  flex-direction: column;

  @media screen and (min-width: ${TABLET_WIDTH}px) {
    gap: 3rem;
    padding: 0 2rem;
    flex-flow: row nowrap;
  }
  @media screen and (min-width: ${DESKTOP_WIDTH}px) {
    gap: 6rem;
    padding: 0 3rem;
    flex-flow: row nowrap;
  }
`;
const BioImageWrapper = styled(ProjectImage)`
  padding: 1rem 0;

  @media screen and (min-width: ${TABLET_WIDTH}px) {
    padding 3rem 0;
  }
  @media screen and (min-width: ${DESKTOP_WIDTH}px) {
    padding: 4rem 0;
  }
`;
const BioDescriptionWrapper = styled(ProjectDescription)`
padding: 0 0 1rem;

@media screen and (min-width: ${TABLET_WIDTH}px) {
  padding 3rem 0;
}
@media screen and (min-width: ${DESKTOP_WIDTH}px) {
  padding: 4rem 0;
`;

const ParagraphWrapper = styled.div`
  padding: 0 0 0.5rem;
`;

const Bio = (props) => {
  return (
    <BioWrapper>
      <BioImageWrapper src="/images/dennis-bio.jpg" alt="bio image" />
      <BioDescriptionWrapper>
        <ParagraphWrapper>
          Dennis is an accomplished hairstylist with a deep passion for his
          craft, consistently delivering high-quality services and sharing his
          expertise through education. With over a decade of experience in the
          salon industry and more than 15 years in the overall field, Dennis has
          established himself as a respected professional.
        </ParagraphWrapper>
        <ParagraphWrapper>
          His commitment to continuous growth and excellence led him to train
          with renowned institutions, including the prestigious Vidal Sassoon
          Academy in London and the esteemed Wella International in New York.
          These invaluable opportunities provided him with technical mastery and
          exposure to cutting-edge trends, such as participating in the
          exclusive New York Fashion Week backstage course.
        </ParagraphWrapper>
        <ParagraphWrapper>
          In 2022, Dennis had the privilege of working alongside the esteemed
          Hair Artist, Laurent Phillipon, an experience that significantly
          enriched his skills and perspective. Collaborating on fashion photo
          shoots and being involved in the backstage preparations of Milan
          Fashion Week allowed him to expand his creative horizons and develop a
          deep understanding of the industry's dynamic nature. Notably, Dennis
          also contributed to a Pepsi Co. commercial shoot, further
          demonstrating his versatility and adaptability.
        </ParagraphWrapper>
        <ParagraphWrapper>
          Driven by a constant desire to learn and evolve, Dennis consistently
          seeks out new knowledge and techniques to enhance his craft. He views
          education as a cornerstone of his professional growth, empowering him
          to bring innovative ideas and techniques to his clients. Dennis's
          dedication to staying at the forefront of his industry sets him apart
          as a hairstylist committed to providing exceptional service.
        </ParagraphWrapper>
        <ParagraphWrapper>
          With his unwavering passion and commitment to education, Dennis is
          poised to make a lasting impact in the hairstyling industry. Through
          his expertise, he aims to elevate the standards of his profession
          while creating unforgettable experiences for his valued clients.
        </ParagraphWrapper>
      </BioDescriptionWrapper>
    </BioWrapper>
  );
};

Bio.propTypes = {};

export default Bio;
