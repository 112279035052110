import React from "react";

import PageHeader from "../components/pageLayout/PageHeader";
import Section, { SectionContent } from "../components/Section";
import Link from "../components/text/Link";
import Gallery from "../components/Gallery";
import ImageFrame from "../components/ImageFrame";

const Work = (props) => {
  return (
    <>
      <PageHeader alt="Work header" src="/images/banner.png" title="Work" />
      <Section backgroundColor={"#F4F4F0"}>
        <SectionContent>
          <Gallery itemsPerRow={3} gap={25}>
            <Link to="/salon">
              <ImageFrame title="Salon" alt="salon" src="/images/salon/7.jpg" />
            </Link>
            <Link to="/projects">
              <ImageFrame
                title="Project"
                alt="projects"
                src="/images/projects/project_a/1.jpg"
              />
            </Link>
            <Link to="/trainings">
              <ImageFrame
                title="Training"
                alt="work3"
                src="/images/training.jpg"
              />
            </Link>
          </Gallery>
        </SectionContent>
      </Section>
    </>
  );
};

Work.propTypes = {};

export default Work;
