import React from "react";
import styled from "@emotion/styled";

const FrameContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;
const ImageWrapper = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  box-shadow: 4px 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  position: relative;
`;
const ImageTitleWrapper = styled.div`
  position: absolute;
  right: 10%;
  bottom: 10%;
  max-width: 50%;
  font-size: 1rem;
  text-decoration: underline;
  color: #f8f9fa;
  letter-spacing: 4px;
  text-underline-offset: 8px;
  background-color: rgba(70, 63, 58, 0.7);
  padding: 0 0.5rem;
`;

const ImageFrame = ({ src, alt, title, ...restProps }) => {
  return (
    <FrameContainer>
      <ImageWrapper alt={alt} src={src} {...restProps} />
      <ImageTitleWrapper>{title}</ImageTitleWrapper>
    </FrameContainer>
  );
};

ImageFrame.propTypes = {};

export default ImageFrame;
